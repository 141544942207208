<app-italo-ecu-logo></app-italo-ecu-logo>

<div id="contact">
    <div class="form">
        <h1>{{'contact.headline' | transloco}}</h1>

        <h2>Du hast eine Frage?</h2>
        <p>
            Du hast eine oder mehrere Fragen zu unseren Produkten,<br>
            findest auf unserer Webseite aber keine Antwort dazu?<br>
            Dann hinterlasse uns schnell und einfach eine Mail!<br><br>
            Anregungen, Meinungen und Feedback sind hier ebenfalls erwünscht.<br><br>
            Formular ausfüllen und ab die Post.<br>
            Wir melden uns umgehend zurück.
        </p>

        <mat-form-field appearance="outline" class="input-full-width">
            <mat-label>Dein Vorname</mat-label>
            <input matInput placeholder="Max" [formControl]="firstName" required>
            <mat-error *ngIf="firstName.invalid">{{getErrorMessage('firstName')}}</mat-error>
        </mat-form-field>

        <br>

        <mat-form-field appearance="outline">
            <mat-label>Dein Nachname</mat-label>
            <input matInput placeholder="Max" [formControl]="lastName" required>
            <mat-error *ngIf="lastName.invalid">{{getErrorMessage('lastName')}}</mat-error>
        </mat-form-field>

        <br>

        <mat-form-field appearance="outline">
            <mat-label>Deine E-Mail Adresse</mat-label>
            <input matInput placeholder="pat@example.com" [formControl]="email" required>
            <mat-error *ngIf="email.invalid">{{getErrorMessage('email')}}</mat-error>
        </mat-form-field>

        <br>

        <mat-form-field appearance="outline">
            <mat-label>Deine Telefonnummer</mat-label>
            <input matInput placeholder="+49 151 156 76 678" [formControl]="phone">
            <mat-error *ngIf="phone.invalid">{{getErrorMessage('phone')}}</mat-error>
        </mat-form-field>

        <br>

        <mat-form-field appearance="outline">
            <mat-label>Betreff</mat-label>
            <input matInput placeholder="Ich habe eine Frage zu..." [formControl]="subject" required>
            <mat-error *ngIf="subject.invalid">{{getErrorMessage('subject')}}</mat-error>
        </mat-form-field>

        <br>

        <mat-form-field appearance="outline" class="message">
            <mat-label>Deine Nachricht</mat-label>
            <textarea matInput placeholder="Hier steht deine Nachricht" [formControl]="message" required></textarea>
            <mat-error *ngIf="message.invalid">{{getErrorMessage('message')}}</mat-error>
        </mat-form-field>

        <br>

        <button mat-raised-button>Absenden</button>
    </div>
    <div class="image">
        <img src="../../../assets/logo/italoecu-kleeblatt.png"
            alt="ItaloEcu - Chiptuning für Alfa Romeo, Fiat, Lancia, Abarth, Jeep, Chrysler, Maserati, Ferrari aus Berlin"
            title="ItaloEcu - Chiptuning für Alfa Romeo, Fiat, Lancia, Abarth, Jeep, Chrysler, Maserati, Ferrari aus Berlin">
    </div>
</div>

<app-language-switcher></app-language-switcher>
<app-navigation></app-navigation>