<nav id="navigation">
    <ul>
        <!--<li><a routerLink="/ueber-uns">Über uns</a></li>
        <li><a routerLink="/tuning-konfigurator">Konfigurator</a></li>
        <li><a routerLink="/galerie">Galerie</a></li>-->
        <!--<li><a routerLink="/kontakt">{{ 'navigation.contact' | transloco }}</a></li>-->
        <li><a routerLink="/impressum">{{'navigation.impress' | transloco }}</a></li>
        <li><a routerLink="/datenschutz">{{'navigation.privacy' | transloco }}</a></li>
    </ul>
</nav>

<div id="social-navigation">
    <div><a href=" https://wa.me/4915115676678?text=Ich+w%C3%BCnsche+Informationen..." target="blank">
            <img src="../../../assets/social/whatsapp.png" alt="Kontaktiere uns per Whatsapp"
                title="Kontaktiere uns per Whatsapp"></a>
    </div>
    <div><a href="https://www.facebook.com/italoecu/" target="blank">
            <img src="../../../assets/social/facebook.png" alt="Besuche unsere Facebook Seite"
                title="Besuche unsere Facebook Seite"></a>
    </div>
    <div><a href="https://www.instagram.com/italoecu/" target="blank">
            <img src="../../../assets/social/instagram.png" alt="Besuche unsere Instagram Seite"
                title="Besuche unsere Instagram Seite"></a>
    </div>
    <div><a href="https://www.youtube.com/@italoecuberlin7741" target="blank">
            <img src="../../../assets/social/youtube.png" alt="Besuche unsere Youtube Seite"
                title="Besuche unsere Youtube Seite"></a>
    </div>
</div>