<div id="intro-slider">
<div class="container image-{{i}}" 
    *ngFor="let image of sliderImages; let i = index" 
    [ngClass]="currentImage.index === i ? 'active' : 'hidden'">
        <h1 class="title" [innerHTML]="image.name"></h1>
        <div (click)="onClick($event)" (swipe)="onSwipe($event)" (swipeleft)="onSwipe($event)"
            (swiperight)="onSwipe($event)" (swipeup)="onSwipe($event)" (swipedown)="onSwipe($event)"
            class="slider-image" [ngStyle]="{'background-image': 'url(' + image.url + ')'}">
        </div>
    </div>
</div>