import { Contact } from './../../lib/contact';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contact: Contact;
  email = new FormControl('', [Validators.required, Validators.email]);
  firstName = new FormControl('', [Validators.required]);
  lastName = new FormControl('', [Validators.required]);
  phone = new FormControl('');
  subject = new FormControl('', [Validators.required]);
  message = new FormControl('', [Validators.required]);

  constructor() { }

  ngOnInit(): void {
  }

  getErrorMessage(fieldName: string) {

    switch (fieldName) {
      case 'email': {
        if (this.email.hasError('required')) {
          return 'Bitte trage deine E-Mail ein';
        }
        return this.email.hasError('email') ? 'Keine gültige E-Mail Adresse' : '';
      }
      case 'firstName': {
        if (this.firstName.hasError('required')) {
          return 'Bitte trage deinen Vornamen ein';
        }
        break;
      }
      case 'lastName' : {
        if (this.lastName.hasError('required')) {
          return 'Bitte trage deinen Nachnamen ein';
        }
        break;
      }
      case 'subject': {
        if (this.subject.hasError('required')) {
          return 'Bitte trage einen Betreff ein';
        }
        break;
      }
      case 'message': {
        if (this.message.hasError('required')) {
          return 'Bitte trage eine Nachricht ein';
        }
        break;
      }
      default: {
        break;
      }
    }
  }

}
