import { PrivacyComponent } from './components/privacy/privacy.component';
import { ImpressComponent } from './components/impress/impress.component';
import { ContactComponent } from './components/contact/contact.component';
import { GaleryComponent } from './components/galery/galery.component';
import { TuningConfiguratorComponent } from './components/tuning-configurator/tuning-configurator.component';
import { PageNotFoundComponentComponent } from './components/page-not-found-component/page-not-found-component.component';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'ueber-uns', component: AboutUsComponent},
  { path: 'tuning-konfigurator', component: TuningConfiguratorComponent},
  { path: 'galerie', component: GaleryComponent},
  { path: 'kontakt', component: ContactComponent},
  { path: 'impressum', component: ImpressComponent},
  { path: 'datenschutz', component: PrivacyComponent},
  { path: 'de', component: HomeComponent},
  { path: 'en', component: HomeComponent},
  { path: '', component: HomeComponent, pathMatch: 'full'},
  { path: '**', component: PageNotFoundComponentComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
