<app-italo-ecu-logo></app-italo-ecu-logo>

<div id="impress">
    <div class="text">
        <h1>Impressum</h1>

        <h2>Verantwortlicher Diensteanbieter:</h2>
        <p>
            André Schubert<br>
            Hafersteig 24<br>
            12683 Berlin<br>
            Deutschland
        </p>

        <h2>Kontakt:</h2>
        <p>
            Email: <a href="mailto:andre.schubert@italoecu.de">andre.schubert@italoecu.de</a><br>
            SMS/Whatsapp: 0151 15676678*<br>
            * Erstkontakt bitte per Whatsapp oder SMS, zeitnahe Rückmeldung<br><br>
            Öffnungszeiten: nur mit Termin / nach Vereinbarung
        </p>

        <h2>Berufliche Angaben:</h2>
        <p>
            Zur Alten Börse 53<br>
            12681 Berlin<br>
            Deutschland<br><br>
            Umsatzsteuer-ID<br>
            DE303400922
        </p>


        <h2>Illustrationen und Grafik:</h2>
        <p>
            Social Media Icons erstellt von<br>
            <a href="https://www.flaticon.com/de/autoren/freepik" title="Freepik">Freepik</a>
            von <a href="https://www.flaticon.com/de/" title="Flaticon">www.flaticon.com</a>
        </p>
    </div>
    <div class="image">
        <img src="../../../assets/logo/italoecu-kleeblatt.png" 
            alt="ItaloEcu - Chiptuning für Alfa Romeo, Fiat, Lancia, Abarth, Jeep, Chrysler, Maserati, Ferrari aus Berlin" 
            title="ItaloEcu - Chiptuning für Alfa Romeo, Fiat, Lancia, Abarth, Jeep, Chrysler, Maserati, Ferrari aus Berlin">
    </div>
</div>

<app-language-switcher></app-language-switcher>
<app-navigation></app-navigation>