import { SliderImage } from './../../lib/slider-image';
import { Component, OnInit } from '@angular/core';
import images from '../../data/slider-images-mock.json';

@Component({
  selector: 'app-intro-slider',
  templateUrl: './intro-slider.component.html',
  styleUrls: ['./intro-slider.component.scss']
})
export class IntroSliderComponent implements OnInit {

  sliderImages: Array<SliderImage> = images;
  currentImage: SliderImage;

  constructor() { }

  ngOnInit(): void {
    this.getRandomImage(this.sliderImages.length);
  }

  onClick(event): void {
    this.rotateImage();
  }

  onSwipe(event): void {
    this.rotateImage();
  }

  private getRandomImage(max): void {
    const rand = Math.floor(Math.random() * Math.floor(max));
    this.currentImage = this.sliderImages[rand];
  }

  private rotateImage() {
    let index = this.currentImage.index;
    if (index + 1 >= this.sliderImages.length) {
      index = 0;
    } else {
      index++;
    }
    this.currentImage = this.sliderImages[index];
  }

}
