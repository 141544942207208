<app-italo-ecu-logo></app-italo-ecu-logo>

<div id="privacy">
    <div class="text">
        <h1>Datenschutzerklärung</h1>
        <h2>Datenschutz</h2>
        <p>Wir haben diese Datenschutzerklärung (Fassung 29.02.2020-311157687) verfasst, um Ihnen gemäß der Vorgaben der
            <a href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311157687"
                target="_blank" rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> zu erklären, welche
            Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher
            dieser Webseite haben.</p>
        <p>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der
            Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.</p>
        <h2>Cookies</h2>
        <p>Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.<br />
            Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
            Datenschutzerklärung besser verstehen.</p>
        <h3>Was genau sind Cookies?</h3>
        <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
            Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine
            Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
        <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten
            verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere
            Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer
            gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221;
            Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines
            Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
        <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
            Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
            Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen
            Ihre gewohnte Standardeinstellung. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie
            beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
        <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
            unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics)
            erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die
            Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine
            Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht
            auf Informationen Ihres PCs zugreifen.</p>
        <p>So können zum Beispiel Cookie-Daten aussehen:</p>
        <ul>
            <li>Name: _ga</li>
            <li>Ablaufzeit: 2 Jahre</li>
            <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
            <li>Beispielhafter Wert: GA1.2.1326744211.152311157687</li>
        </ul>
        <p>Ein Browser sollte folgende Mindestgrößen unterstützen:</p>
        <ul>
            <li>Ein Cookie soll mindestens 4096 Bytes enthalten können</li>
            <li>Pro Domain sollen mindestens 50 Cookies gespeichert werden können</li>
            <li>Insgesamt sollen mindestens 3000 Cookies gespeichert werden können</li>
        </ul>
        <h3>Welche Arten von Cookies gibt es?</h3>
        <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in der
            folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
            verschiedenen Arten von HTTP-Cookies eingehen.</p>
        <p>Man kann 4 Arten von Cookies unterscheiden:</p>
        <p>
            <strong>Unbedingt notwendige Cookies<br />
            </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel
            braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten
            weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst
            wenn der User sein Browserfenster schließt.</p>
        <p>
            <strong>Funktionelle Cookies<br />
            </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt.
            Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen
            Browsern gemessen.</p>
        <p>
            <strong>Zielorientierte Cookies<br />
            </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene
            Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
        <p>
            <strong>Werbe-Cookies<br />
            </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell
            angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
        <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie
            zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
        <h3>Wie kann ich Cookies löschen?</h3>
        <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
            Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur teilweise zuzulassen
            oder zu deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen
            Cookies zulassen.</p>
        <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
            Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
        <p>
            <a href="https://support.google.com/chrome/answer/95647?tid=311157687" target="_blank"
                rel="noopener">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
        </p>
        <p>
            <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311157687" target="_blank"
                rel="noopener">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
        </p>
        <p>
            <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311157687"
                target="_blank" rel="noopener">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
                Computer abgelegt haben</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311157687"
                target="_blank" rel="noopener">Internet Explorer: Löschen und Verwalten von Cookies</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311157687"
                target="_blank" rel="noopener">Microsoft Edge: Löschen und Verwalten von Cookies</a>
        </p>
        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
            informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob
            Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten ist es Sie
            suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren
            Chrome&#8221; im Falle eines Chrome Browsers oder tauschen das Wort &#8220;Chrome&#8221; gegen den Namen
            Ihres Browsers, z.B. Edge, Firefox, Safari aus.</p>
        <h3>Wie sieht es mit meinem Datenschutz aus?</h3>
        <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von
            Cookies eine Einwilligung des Website-Besuchers (also von Ihnen) verlangt. Innerhalb der EU-Länder gibt es
            allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland wurden die
            Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser
            Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>
        <p>Wenn Sie mehr über Cookies wissen möchten und vor technischen Dokumentationen nicht zurückscheuen, empfehlen
            wir <a href="https://tools.ietf.org/html/rfc6265" target="_blank"
                rel="nofollow noopener">https://tools.ietf.org/html/rfc6265</a>, dem Request for Comments der Internet
            Engineering Task Force (IETF) namens &#8220;HTTP State Management Mechanism&#8221;.</p>
        <h2>Speicherung persönlicher Daten</h2>
        <p>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name,
            E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder
            Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils
            angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.</p>
        <p>Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt
            ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
            Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen,
            dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>
        <p>Wenn Sie uns persönliche Daten per E-Mail schicken &#8211; somit abseits dieser Webseite &#8211; können wir
            keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten
            niemals unverschlüsselt per E-Mail zu übermitteln.</p>
        <p>Die Rechtsgrundlage besteht nach <a
                href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311157687"
                target="_blank" rel="noopener">Artikel 6  Absatz 1 a DSGVO</a> (Rechtmäßigkeit der Verarbeitung) darin,
            dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen
            Einwilligung jederzeit widerrufen &#8211; eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im
            Impressum.</p>
        <h2>Rechte laut Datenschutzgrundverordnung</h2>
        <p>Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
        <ul>
            <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
            <li>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
            <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
            <li>Recht auf Benachrichtigung &#8211; Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung
                personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)</li>
            <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
            <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
            <li>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling —
                beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</li>
        </ul>
        <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
            datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die <a
                class="311157687" href="https://www.bfdi.bund.de" target="_blank" rel="noopener">Bundesbeauftragte für
                den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden.</p>
        <h2>Auswertung des Besucherverhaltens</h2>
        <p>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser
            Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem
            Verhalten auf dieser Website nicht auf Ihre Person schließen.</p>
        <p>Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden
            Datenschutzerklärung.</p>
        <h2>TLS-Verschlüsselung mit https</h2>
        <p>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung <a
                href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&#038;tid=311157687"
                target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport
            Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den
            Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung
            am kleinen Schloßsymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil
            unserer Internetadresse.</p>
        <h2>Google Maps Datenschutzerklärung</h2>
        <p>Wir benützen auf unserer Website Google Maps der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View,
            CA 94043, USA). Mit Google Maps können wir Standorte visuell besser darstellen und damit unser Service
            verbessern. Durch die Verwendung von Google Maps werden Daten an Google übertragen und auf den
            Google-Servern gespeichert. Hier wollen wir nun genauer darauf eingehen, was Google Maps ist, warum wir
            diesen Google-Dienst in Anspruch nehmen, welche Daten gespeichert werden und wie Sie dies unterbinden
            können.</p>
        <h3>Was ist Google Maps?</h3>
        <p>Google Maps ist ein Online-Kartendienst der Firma Google Inc. Mit Google Maps können Sie im Internet über
            einen PC oder über eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen
            suchen. Wenn Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere
            Informationen über die Firma angezeigt. Um  die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte
            eines Standorts per HTML-Code in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche als
            Straßenkarte oder als Luft- bzw. Satellitenbild an. Dank der Street View Bilder und den qualitativ
            hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.</p>
        <h3>Warum verwenden wir Google Maps auf unserer Website?</h3>
        <p>All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf
            unserer Website zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten
            Informationen zu diversen Standorten liefern. Dank Google Maps sehen Sie auf einen Blick wo wir unseren
            Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können
            den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad
            abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres Kundenservice.</p>
        <h3>Welche Daten werden von Google Maps gespeichert?</h3>
        <p>Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und
            speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und die Breiten- bzw.
            Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse
            gespeichert. Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie
            darüber nur informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Website eingebunden
            haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über
            Ihr Userverhalten. Google nutzt diese Daten in erster Linie, um eigene Dienste zu optimieren und
            individuelle, personalisierte Werbung für Sie bereitzustellen.</p>
        <p>Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:</p>
        <ul>
            <li>
                <strong>Name:</strong> NID</li>
            <li>
                <strong>Ablaufzeit:</strong> nach 6 Monaten</li>
            <li>
                <strong>Verwendung:</strong> NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche
                anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen Suchanfragen
                oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das
                Cookie enthält eine einzigartige ID, die Google benutzt, persönliche Einstellungen des Users für
                Werbezwecke zu sammeln.</li>
            <li>
                <strong>Beispielwert:</strong> 188=h26c1Ktha7fCQTx8rXgLyATyITJ311157687</li>
        </ul>
        <p>
            <strong>Anmerkung:</strong> Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit
            gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen bei Google nie auszuschließen. Um
            das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google Maps
            eingebunden war.</p>
        <h3>Wie lange und wo werden die Daten gespeichert?</h3>
        <p>Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in
            Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau
            nachlesen wo sich die Google-Rechenzentren befinden: <a
                href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank"
                rel="noopener">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
        </p>
        <p>Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und
            werden vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle
            Notfallprogramme. Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die
            Server beeinträchtigt, bleiben die Daten mit hoher Wahrscheinlich dennoch geschützt.</p>
        <p>Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die
            Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum
            Beispiel Werbedaten) in Serverprotokollen, indem sie einen Teil der IP-Adresse und Cookie-Informationen nach
            9 bzw.18 Monaten löschen.</p>
        <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen
            zur Standortbestimmung und Web-/App-Aktivität &#8211; abhängig von Ihrer Entscheidung &#8211; entweder 3
            oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch
            jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen,
            müssen Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und
            Personalisierung“ und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- bzw.
            ausschalten.</p>
        <p>In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem
            welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Die folgenden
            Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:</p>
        <p>
            <a href="https://support.google.com/chrome/answer/95647?tid=311157687" target="_blank"
                rel="noopener">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
        </p>
        <p>
            <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311157687" target="_blank"
                rel="noopener">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
        </p>
        <p>
            <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311157687"
                target="_blank" rel="noopener">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
                Computer abgelegt haben</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311157687"
                target="_blank" rel="noopener">Internet Explorer: Löschen und Verwalten von Cookies</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311157687"
                target="_blank" rel="noopener">Microsoft Edge: Löschen und Verwalten von Cookies</a>
        </p>
        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
            informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob
            Sie es erlauben oder nicht.</p>
        <p>Google ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
            Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI" target="_blank"
                rel="noopener">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI</a>. Wenn Sie mehr über
            die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung
            des Unternehmens unter <a href="https://policies.google.com/privacy?hl=de" target="_blank"
                rel="noopener">https://policies.google.com/privacy?hl=de</a>.</p>
        <h2>Google Fonts Datenschutzerklärung</h2>
        <p>Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf
            unserer Webseite.</p>
        <p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen.
            Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden
            über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die
            Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein
            Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von
            Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und
            der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht,
            werden wir uns noch im Detail ansehen.</p>
        <h3>Was sind Google Fonts?</h3>
        <p>Google Fonts (früher Google Web Fonts) ist ein interaktives Verzeichnis mit mehr als 800 Schriftarten, die
            die <a href="https://de.wikipedia.org/wiki/Google_LLC?tid=311157687" target="_blank" rel="noopener">Google
                LLC</a> zur freien Verwendung bereitstellt.</p>
        <p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter
            der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen. Somit können wir sie frei
            verwenden, ohne dafür Lizenzgebühren zu zahlen.</p>
        <h3>Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
        <p>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem
            eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu
            halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist
            speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt
            die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sogenannte sichere Web
            Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und
            mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten
            optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine
            plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser ( Google Chrome, Mozilla
            Firefox, Apple Safari, Opera)  und funktioniert zuverlässig auf den meisten modernen mobilen
            Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod).</p>
        <p>Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie
            möglich darstellen können. Nach dem Art. 6 Abs. 1 f lit. F DSGVO stellt das bereits ein „berechtigtes
            Interesse“ an der Verarbeitung von personenbezogenen Daten dar. Unter „berechtigtem Interesse“ versteht man
            in diesem Fall sowohl rechtliche als auch wirtschaftliche oder ideelle Interessen, die vom Rechtssystem
            anerkannt werden.</p>
        <h3>Welche Daten werden von Google gespeichert?</h3>
        <p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen
            externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre
            IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um die Erfassung, Speicherung und
            Verwendung von Endnutzerdaten auf das zu reduzieren, was für eine effiziente Bereitstellung von Schriften
            nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als
            Datenübermittler im Softwarebereich.</p>
        <p>Google Fonts speichert CSS- und Font-Anfragen sicher bei Google und ist somit geschützt. Durch die
            gesammelten Nutzungszahlen kann Google die Beliebtheit der Schriften feststellen. Die Ergebnisse
            veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet
            Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden.
            Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. BigQuery ist ein Webservice
            von Google für Unternehmen, die große Datenmengen bewegen und analysieren wollen.</p>
        <p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie IP-Adresse,
            Spracheinstellungen, Bildschirmauflösung des Browsers, Version des Browsers und Name des Browsers
            automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar
            feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p>
        <h3>Wie lange und wo werden die Daten gespeichert?</h3>
        <p>Anfragen für CSS-Assets speichert Google einen Tag lang auf Ihren Servern, die hauptsächlich außerhalb der EU
            angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein
            Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart
            einer Webseite ändern kann.</p>
        <p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von
            Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen,
            werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später
            besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren,
            die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.</p>
        <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten
            werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können,
            müssen Sie den Google-Support auf <a href="https://support.google.com/?hl=de&amp;tid=311157687"
                target="_blank" rel="noopener">https://support.google.com/?hl=de&amp;tid=311157687</a> kontaktieren.
            Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
        <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir
            können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite
            rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a
                href="https://developers.google.com/fonts/faq?tid=311157687" target="_blank"
                rel="noopener">https://developers.google.com/fonts/faq?tid=311157687</a>. Dort geht zwar Google auf
            datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung
            sind nicht enthalten. Es ist relativ schwierig (beinahe unmöglich), von Google wirklich präzise
            Informationen über gespeicherten Daten zu bekommen.</p>
        <p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch
            auf <a href="https://policies.google.com/privacy?hl=de&amp;tid=311157687" target="_blank"
                rel="noopener">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.</p>
        <h2>Google Fonts Lokal Datenschutzerklärung</h2>
        <p>Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf
            unserer Webseite. Wir haben die Google-Schriftarten lokal, d.h. auf unserem Webserver &#8211; nicht auf den
            Servern von Google &#8211; eingebunden. Dadurch gibt es keine Verbindung zu Server von Google und somit auch
            keine Datenübertragung bzw. Speicherung.</p>
        <h3>Was sind Google Fonts?</h3>
        <p>Google Fonts (früher Google Web Fonts) ist ein interaktives Verzeichnis mit mehr als 800 Schriftarten, die
            die <a href="https://de.wikipedia.org/wiki/Google_LLC?tid=311157687">Google LLC</a> zur freien Verwendung
            bereitstellt. Mit Google Fonts könnte man die Schriften nutzen, ohne sie auf den eigenen Server hochzuladen.
            Doch um diesbezüglich jede Informationsübertragung zum Google-Server zu unterbinden, haben wir die
            Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden
            keine Daten an Google Fonts weiter.</p>
        <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir
            können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite
            rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a
                href="https://developers.google.com/fonts/faq?tid=311157687">https://developers.google.com/fonts/faq?tid=311157687</a>.
        </p>
        <h2>Google Analytics Datenschutzerklärung</h2>
        <p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen
            Unternehmens Google LLC (1600 Amphitheatre Parkway Mountain View, CA 94043, USA). Google Analytics sammelt
            Daten über Ihre Handlungen auf unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese
            Aktion in einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die wir von
            Google Analytics erhalten, können wir unsere Website und unser Service besser an Ihre Wünsche anpassen. Im
            Folgenden gehen wir näher auf das Tracking Tool ein und informieren Sie vor allem darüber, welche Daten
            gespeichert werden und wie Sie das verhindern können.</p>
        <h3>Was ist Google Analytics?</h3>
        <p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google
            Analytics funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere
            Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausführen.
            Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort
            gespeichert.</p>
        <p>Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter
            anderem um folgende Berichte handeln:</p>
        <ul>
            <li>Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer,
                wer sich für unser Service interessiert.</li>
            <li>Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter analysieren und
                verbessern.</li>
            <li>Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber, wie wir mehr
                Menschen für unser Service begeistern können.</li>
            <li>Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können
                nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.</li>
            <li>Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft
                eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem
                Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere
                Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.</li>
            <li>Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel
                sehen wir wie viele User gerade diesen Text lesen.</li>
        </ul>
        <h3>Warum verwenden wir Google Analytics auf unserer Webseite?</h3>
        <p>Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die Statistiken und
            Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
        <p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer Website.
            Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter
            gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit
            sehr genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die
            Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und kostengünstiger
            durchzuführen. Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die
            sich dafür interessieren.</p>
        <h3>Welche Daten werden von Google Analytics gespeichert?</h3>
        <p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem
            Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal
            unsere Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden
            gemeinsam mit dieser User-ID gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile
            auszuwerten.</p>
        <p>Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen.
            Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie auch andere
            Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte Daten mit
            Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als
            Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</p>
        <p>Folgende Cookies werden von Google Analytics verwendet:</p>
        <p>
            <strong>Name:</strong> _ga<br />
            <strong>Wert:</strong>2.1326744211.152311157687-5<br />
            <strong>Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu
            speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.<br />
            <strong>Ablaufdatum:</strong> nach 2 Jahre</p>
        <p>
            <strong>Name:</strong> _gid<br />
            <strong>Wert:</strong>2.1687193234.152311157687-1<br />
            <strong>Verwendungszweck:</strong> Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br />
            <strong>Ablaufdatum:</strong> nach 24 Stunden</p>
        <p>
            <strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br />
            <strong>Wert:</strong> 1<br />
            <strong>Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics
            über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_
            &lt;property-id&gt;.<br />
            <strong>Ablaufdatum: </strong>nach 1 Minute</p>
        <p>
            <strong>Name:</strong> AMP_TOKEN<br />
            <strong>Wert:</strong> keine Angaben<br />
            <strong>Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst
            abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen Fehler
            hin.<br />
            <strong>Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr</p>
        <p>
            <strong>Name:</strong> __utma<br />
            <strong>Wert:</strong>1564498958.1564498958.1564498958.1<br />
            <strong>Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und
            sie Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google Analytics gesendet
            werden.<br />
            <strong>Ablaufdatum:</strong> nach 2 Jahre</p>
        <p>
            <strong>Name:</strong> __utmt<br />
            <strong>Wert:</strong> 1<br />
            <strong>Verwendungszweck:</strong> Das Cookie wird wie _gat_gtag_UA_&lt;property-id&gt; zum Drosseln der
            Anforderungsrate verwendet.<br />
            <strong>Ablaufdatum:</strong> nach 10 Minuten</p>
        <p>
            <strong>Name:</strong> __utmb<br />
            <strong>Wert:</strong>3.10.1564498958<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird
            jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.<br />
            <strong>Ablaufdatum:</strong> nach 30 Minuten</p>
        <p>
            <strong>Name:</strong> __utmc<br />
            <strong>Wert:</strong> 167421564<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende
            Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur solange gespeichert, bis Sie
            den Browser wieder schließen.<br />
            <strong>Ablaufdatum:</strong> Nach Schließung des Browsers</p>
        <p>
            <strong>Name:</strong> __utmz<br />
            <strong>Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br />
            <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um die Quelle des Besucheraufkommens auf
            unserer Website zu identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere Website gekommen
            sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.<br />
            <strong>Ablaufdatum:</strong> nach 6 Monate</p>
        <p>
            <strong>Name:</strong> __utmv<br />
            <strong>Wert:</strong> keine Angabe<br />
            <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern.
            Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br />
            <strong>Ablaufdatum:</strong> nach 2 Jahre</p>
        <p>
            <strong>Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die
            Wahl ihrer Cookies immer wieder auch verändert.</p>
        <p>Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics erhoben werden:
        </p>
        <p>
            <strong>Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche,
            die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.</p>
        <p>
            <strong>Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite
            verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.
        </p>
        <p>
            <strong>Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer
            Website nur eine Seite ansehen und dann unsere Website wieder verlassen.</p>
        <p>
            <strong>Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung
            machen, erhebt Google Analytics diese Daten.</p>
        <p>
            <strong>IP-Adresse:</strong> Die IP-Adresse wird nur in gekürzter Form dargestellt, damit keine eindeutige
            Zuordnung möglich ist.</p>
        <p>
            <strong>Standort:</strong> Über die IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden.
            Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.</p>
        <p>
            <strong>Technische Informationen:</strong> Zu den technischen Informationen zählen unter anderem Ihr
            Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.</p>
        <p>
            <strong>Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert natürlich auch über
            welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</p>
        <p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z.B., wenn Sie ein Video über
            unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten.
            Die Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der
            Datenspeicherung durch Google Analytics.</p>
        <h3>Wie lange und wo werden die Daten gespeichert?</h3>
        <p>Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich
            werden Ihr Daten meist auf amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo sich die
            Google-Rechenzentren befinden: <a href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
                target="_blank" rel="noopener">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
        </p>
        <p>Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten
            schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es
            entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder
            Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.
        </p>
        <p>Standardisiert ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten eingestellt.
            Dann werden Ihre Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer von
            Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur Verfügung:</p>
        <ul>
            <li>Löschung nach 14 Monaten</li>
            <li>Löschung nach 26 Monaten</li>
            <li>Löschung nach 38 Monaten</li>
            <li>Löschung nach 50 Monaten</li>
            <li>Keine automatische Löschung</li>
        </ul>
        <p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese
            Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der
            DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig
            von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer
            größeren Einheit.</p>
        <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten,
            sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von
            Google Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten
            verwendet. Das Browser-Add-on können Sie unter <a href="https://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank" rel="noopener">https://tools.google.com/dlpage/gaoptout?hl=de</a> runterladen und
            installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics
            deaktiviert wird.</p>
        <p>Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics) deaktivieren, löschen oder verwalten
            wollen, gibt es für jeden Browser eine eigene Anleitung:</p>
        <p>
            <a href="https://support.google.com/chrome/answer/95647?tid=311157687" target="_blank"
                rel="noopener">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
        </p>
        <p>
            <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311157687" target="_blank"
                rel="noopener">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
        </p>
        <p>
            <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311157687"
                target="_blank" rel="noopener">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
                Computer abgelegt haben</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311157687"
                target="_blank" rel="noopener">Internet Explorer: Löschen und Verwalten von Cookies</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311157687"
                target="_blank" rel="noopener">Microsoft Edge: Löschen und Verwalten von Cookies</a>
        </p>
        <p>Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und
            sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI" target="_blank"
                rel="follow noopener">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;tid=311157687</a>.
            Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google
            Analytics näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden
            Links: <a href="http://www.google.com/analytics/terms/de.html" target="_blank"
                rel="noopener">http://www.google.com/analytics/terms/de.html</a> und <a
                href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank"
                rel="noopener">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
        <h2>Google Analytics IP-Anonymisierung</h2>
        <p>Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics implementiert. Diese
            Funktion wurde von Google entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen und
            Empfehlungen der lokalen Datenschutzbehörden einhalten kann, wenn diese eine Speicherung der vollständigen
            IP-Adresse untersagen. Die Anonymisierung bzw. Maskierung der IP findet statt, sobald die IP-Adressen im
            Google Analytics-Datenerfassungsnetzwerk eintreffen und bevor eine Speicherung oder Verarbeitung der Daten
            stattfindet.</p>
        <p>Mehr Informationen zur IP-Anonymisierung finden Sie auf <a
                href="https://support.google.com/analytics/answer/2763052?hl=de" target="_blank"
                rel="noopener">https://support.google.com/analytics/answer/2763052?hl=de</a>.</p>
        <h2>Google Analytics Berichte zu demografischen Merkmalen und Interessen</h2>
        <p>Wir haben in Google Analytics die Funktionen für Werbeberichte eingeschaltet. Die Berichte zu demografischen
            Merkmalen und Interessen enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können wir uns &#8211;
            ohne diese Daten einzelnen Personen zuordnen zu können &#8211; ein besseres Bild von unseren Nutzern machen.
            Mehr über die Werbefunktionen erfahren Sie <a
                href="https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad" target="_blank"
                rel="noopener">auf https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad</a>.</p>
        <p>Sie können die Nutzung der Aktivitäten und Informationen Ihres Google Kontos unter “Einstellungen für
            Werbung” auf <a
                href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> per
            Checkbox beenden.</p>
        <h2>Google Analytics Deaktivierungslink</h2>
        <p>Wenn Sie auf folgenden <strong>Deaktivierungslink</strong> klicken, können Sie verhindern, dass Google
            weitere Besuche auf dieser Webseite erfasst. Achtung: Das Löschen von Cookies, die Nutzung des
            Inkognito/Privatmodus ihres Browsers, oder die Nutzung eines anderen Browsers führt dazu, dass wieder Daten
            erhoben werden.</p>
        [google_analytics_optout]Google Analytics deaktivieren[/google_analytics_optout]
        <p>&nbsp;</p>
        <h2>Google Analytics Zusatz zur Datenverarbeitung</h2>
        <p>Wir haben mit Google einen Direktkundenvertrag zur Verwendung von Google Analytics abgeschlossen, indem wir
            den “Zusatz zur Datenverarbeitung” in Google Analytics akzeptiert haben.</p>
        <p>Mehr über den Zusatz zur Datenverarbeitung für Google Analytics finden Sie hier: <a
                href="https://support.google.com/analytics/answer/3379636?hl=de&amp;utm_id=ad" target="_blank"
                rel="noopener">https://support.google.com/analytics/answer/3379636?hl=de&amp;utm_id=ad</a>
        </p>
        <h2>Google Analytics Google-Signale Datenschutzerklärung</h2>
        <p>Wir haben in Google Analytics die Google-Signale aktiviert. So werden die bestehenden
            Google-Analytics-Funktionen (Werbeberichte, Remarketing, gerätübergreifende Berichte und Berichte zu
            Interessen und demografische Merkmale) aktualisiert, um zusammengefasste und anonymisierte Daten von Ihnen
            zu erhalten, sofern Sie personalisierte Anzeigen in Ihrem Google-Konto erlaubt haben.</p>
        <p>Das besondere daran ist, dass es sich dabei um ein Cross-Device-Tracking handelt. Das heißt Ihre Daten können
            geräteübergreifend analysiert werden. Durch die Aktivierung von Google-Signale werden Daten erfasst und mit
            dem Google-Konto verknüpft. Google kann dadurch zum Beispiel erkennen, wenn Sie auf unsere Webseite über ein
            Smartphone ein Produkt ansehen und erst später über einen Laptop das Produkt kaufen. Dank der Aktivierung
            von Google-Signale können wir gerätübergreifende Remarketing-Kampagnen starten, die sonst in dieser Form
            nicht möglich wären. Remarketing bedeutet, dass wir Ihnen auch auf anderen Webseiten unser Angebot zeigen
            können.</p>
        <p>In Google Analytics werden zudem durch die Google-Signale weitere Besucherdaten wie Standort, Suchverlauf,
            YouTube-Verlauf und Daten über Ihre Handlungen auf unserer Webseite, erfasst. Wir erhalten dadurch von
            Google bessere Werbeberichte und nützlichere Angaben zu Ihren Interessen und demografischen Merkmalen. Dazu
            gehören Ihr Alter, welche Sprache sie sprechen, wo Sie wohnen oder welchem Geschlecht Sie angehören. Weiters
            kommen auch noch soziale Kriterien wie Ihr Beruf, Ihr Familienstand oder Ihr Einkommen hinzu. All diese
            Merkmal helfen Google Analytics Personengruppen bzw. Zielgruppen zu definieren.</p>
        <p>Die Berichte helfen uns auch Ihr Verhalten, Ihre Wünsche und Interessen besser einschätzen zu können. Dadurch
            können wir unsere Dienstleistungen und Produkte für Sie optimieren und anpassen. Diese Daten laufen
            standardmäßig nach 26 Monaten ab. Bitte beachten Sie, dass diese Datenerfassung nur erfolgt, wenn Sie
            personalisierte Werbung in Ihrem Google-Konto zugelassen haben. Es handelt sich dabei immer um
            zusammengefasste und anonyme Daten und nie um Daten einzelner Personen. In Ihrem Google-Konto können Sie
            diese Daten verwalten bzw. auch löschen.</p>
        <h2>Automatische Datenspeicherung</h2>
        <p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so
            auch auf dieser Webseite.</p>
        <p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese
            Webseite gespeichert ist) automatisch Daten wie</p>
        <ul>
            <li>die Adresse (URL) der aufgerufenen Webseite</li>
            <li>Browser und Browserversion</li>
            <li>das verwendete Betriebssystem</li>
            <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
            <li>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</li>
            <li>Datum und Uhrzeit</li>
        </ul>
        <p>in Dateien (Webserver-Logfiles).</p>
        <p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben
            diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
            rechtswidrigem Verhalten eingesehen werden.</p>
        <h2>Facebook-Pixel Datenschutzerklärung</h2>
        <p>Wir verwenden auf unserer Webseite das Facebook-Pixel von Facebook. Dafür haben wir einen Code auf unserer
            Webseite implementiert. Der Facebook-Pixel ist ein Ausschnitt aus JavaScript-Code, der eine Ansammlung von
            Funktionen lädt, mit denen Facebook Ihre Userhandlungen verfolgen kann, sofern Sie über Facebook-Ads auf
            unsere Webseite gekommen sind. Wenn Sie beispielsweise ein Produkt auf unserer Webseite erwerben, wird das
            Facebook-Pixel ausgelöst und speichert Ihre Handlungen auf unserer Webseite in einem oder mehreren Cookies.
            Diese Cookies ermöglichen es Facebook Ihre Userdaten (Kundendaten wie IP-Adresse, User-ID) mit den Daten
            Ihres Facebook-Kontos abzugleichen. Dann löscht Facebook diese Daten wieder. Die erhobenen Daten sind für
            uns anonym und nicht einsehbar und werden nur im Rahmen von Werbeanzeigenschaltungen nutzbar. Wenn Sie
            selbst Facebook-User sind und angemeldet sind, wird der Besuch unserer Webseite automatisch Ihrem
            Facebook-Benutzerkonto zugeordnet.</p>
        <p>Wir wollen unsere Dienstleistungen bzw. Produkte nur jenen Menschen zeigen, die sich auch wirklich dafür
            interessieren. Mithilfe von Facebook-Pixel können unsere Werbemaßnahmen besser auf Ihre Wünsche und
            Interessen abgestimmt werden. So bekommen Facebook-User (sofern sie personalisierte Werbung erlaubt haben)
            passende Werbung zu sehen. Weiters verwendet Facebook die erhobenen Daten zu Analysezwecken und eigenen
            Werbeanzeigen.</p>
        <p>Im Folgenden zeigen wir Ihnen jene Cookies, die durch das Einbinden von Facebook-Pixel auf einer Testseite
            gesetzt wurden. Bitte beachten Sie, dass dies nur Beispiel-Cookies sind. Je nach Interaktion auf unserer
            Webseite werden unterschiedliche Cookies gesetzt.</p>
        <p>
            <strong>Name:</strong> _fbp<br />
            <strong>Wert:</strong> fb.1.1568287647279.257405483-6311157687-7<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie verwendet Facebook, um Werbeprodukte anzuzeigen.<br />
            <strong>Ablaufdatum:</strong> nach 3 Monaten</p>
        <p>
            <strong>Name:</strong> fr<br />
            <strong>Wert:</strong> 0aPf312HOS5Pboo2r..Bdeiuf&#8230;1.0.Bdeiuf.<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, damit Facebook-Pixel auch ordentlich
            funktioniert.<br />
            <strong>Ablaufdatum:</strong> nach 3 Monaten</p>
        <p>
            <strong>Name:</strong> comment_author_50ae8267e2bdf1253ec1a5769f48e062311157687-3<br />
            <strong>Wert:</strong> Name des Autors<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie speichert den Text und den Namen eines Users, der
            beispielsweise einen Kommentar hinterlässt.<br />
            <strong>Ablaufdatum:</strong> nach 12 Monaten</p>
        <p>
            <strong>Name:</strong> comment_author_url_50ae8267e2bdf1253ec1a5769f48e062<br />
            <strong>Wert:</strong> https%3A%2F%2Fwww.testseite…%2F (URL des Autors)<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie speichert die URL der Website, die der User in einem
            Textfeld auf unserer Webseite eingibt.<br />
            <strong>Ablaufdatum:</strong> nach 12 Monaten</p>
        <p>
            <strong>Name:</strong> comment_author_email_50ae8267e2bdf1253ec1a5769f48e062<br />
            <strong>Wert:</strong> E-Mail-Adresse des Autors<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie speichert die E-Mail-Adresse des Users, sofern er sie auf
            der Website bekannt gegeben hat.<br />
            <strong>Ablaufdatum:</strong> nach 12 Monaten</p>
        <p>
            <strong>Anmerkung: </strong>Die oben genannten Cookies beziehen sich auf ein individuelles Userverhalten.
            Speziell bei der Verwendung von Cookies sind Veränderungen bei Facebook nie auszuschließen.</p>
        <p>Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen unter <a
                href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank"
                rel="noopener">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a> selbst
            verändern. Falls Sie kein Facebook-User sind, können Sie auf <a
                href="http://www.youronlinechoices.com/de/praferenzmanagement/?tid=311157687" target="_blank"
                rel="noopener">http://www.youronlinechoices.com/de/praferenzmanagement/</a> grundsätzlich Ihre
            nutzungsbasierte Online-Werbung verwalten. Dort haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu
            aktivieren.</p>
        <p>Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen, empfehlen wir Ihnen die eigenen
            Datenrichtlinien des Unternehmens auf <a href="https://www.facebook.com/policy.php" target="_blank"
                rel="noopener">https://www.facebook.com/policy.php</a>.</p>
        <h2>Facebook Automatischer erweiterter Abgleich Datenschutzerklärung</h2>
        <p>Wir haben im Rahmen der Facebook-Pixel-Funktion auch den automatischen erweiterten Abgleich (engl. Automatic
            Advanced Matching) aktiviert. Diese Funktion des Pixels ermöglicht uns, gehashte E-Mails, Namen, Geschlecht,
            Stadt, Bundesland, Postleitzahl und Geburtsdatum oder Telefonnummer als zusätzliche Informationen an
            Facebook zu senden, sofern Sie uns diese Daten zur Verfügung gestellt haben. Diese Aktivierung ermöglicht
            uns Werbekampagnen auf Facebook noch genauer auf Menschen, die sich für unsere Dienstleistungen oder
            Produkte interessieren, anzupassen.</p>
        <h2>Eingebettete Social Media Elemente Datenschutzerklärung</h2>
        <p>Wir binden auf unserer Webseite Elemente von Social Media Diensten ein um Bilder, Videos und Texte
            anzuzeigen.<br />
            Durch den Besuch von Seiten die diese Elemente darstellen, werden Daten von Ihrem Browser zum jeweiligen
            Social Media Dienst übertragen und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.<br />
            Die folgenden Links führen Sie zu den Seiten der jeweiligen Social Media Dienste wo erklärt wird, wie diese
            mit Ihren Daten umgehen:</p>
        <ul>
            <li>Instagram-Datenschutzrichtlinie: <a href="https://help.instagram.com/519522125107875?tid=311157687"
                    target="_blank" rel="noopener">https://help.instagram.com/519522125107875</a>
            </li>
            <li>Für YouTube gilt die Google Datenschutzerklärung: <a
                    href="https://policies.google.com/privacy?hl=de&amp;tid=311157687" target="_blank"
                    rel="noopener">https://policies.google.com/privacy?hl=de</a>
            </li>
            <li>Facebook-Datenrichtline: <a href="https://www.facebook.com/about/privacy?tid=311157687" target="_blank"
                    rel="noopener">https://www.facebook.com/about/privacy</a>
            </li>
            <li>Twitter Datenschutzrichtlinie: <a href="https://twitter.com/de/privacy?tid=311157687" target="_blank"
                    rel="noopener">https://twitter.com/de/privacy</a>
            </li>
        </ul>
        <h2>Facebook Datenschutzerklärung</h2>
        <p>Wir verwenden auf unserer Webseite ausgewählte Facebook Tools von Facebook. Facebook ist ein Social Media
            Network des Unternehmens Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland.
            Mithilfe dieser Tools können wir Ihnen und Menschen, die sich für unsere Produkte und Dienstleistungen
            interessieren, das bestmögliche Angebot bieten. Im Folgenden geben wir einen Überblick über die
            verschiedenen Facebook Tools, welche Daten an Facebook gesendet werden und wie Sie diese Daten löschen
            können.</p>
        <h3>Was sind Facebook-Tools?</h3>
        <p>Neben vielen anderen Produkten bietet Facebook auch die sogenannten &#8220;Facebook Business Tools&#8221;
            an. Das ist die offizielle Bezeichnung von Facebook. Da der Begriff aber kaum bekannt ist, haben wir uns
            dafür entschieden, sie lediglich Facebook-Tools zu nennen. Darunter finden sich unter anderem:</p>
        <ul>
            <li>Facebook-Pixel</li>
            <li>soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)</li>
            <li>Facebook Login</li>
            <li>Account Kit</li>
            <li>APIs (Programmierschnittstelle)</li>
            <li>SDKs (Sammlung von Programmierwerkzeugen)</li>
            <li>Plattform-Integrationen</li>
            <li>Plugins</li>
            <li>Codes</li>
            <li>Spezifikationen</li>
            <li>Dokumentationen</li>
            <li>Technologien und Dienstleistungen</li>
        </ul>
        <p>Durch diese Tools erweitert Facebook Dienstleistungen und hat die Möglichkeit, Informationen über
            User-Aktivitäten außerhalb von Facebook zu erhalten.</p>
        <h3>Warum verwenden wir Facebook-Tools auf unserer Webseite?</h3>
        <p>Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen, die sich auch wirklich dafür
            interessieren. Mithilfe von Werbeanzeigen (Facebook-Ads) können wir genau diese Menschen erreichen. Damit
            den Usern passende Werbung gezeigt werden kann, benötigt Facebook allerdings Informationen über die Wünsche
            und Bedürfnisse der Menschen. So werden dem Unternehmen Informationen über das Userverhalten (und
            Kontaktdaten) auf unserer Webseite zur Verfügung gestellt. Dadurch sammelt Facebook bessere User-Daten und
            kann interessierten Menschen die passende Werbung über unsere Produkte bzw. Dienstleistungen anzeigen. Die
            Tools ermöglichen somit maßgeschneiderte Werbekampagnen auf Facebook.</p>
        <p>Daten über Ihr Verhalten auf unserer Webseite nennt Facebook „Event-Daten“. Diese werden auch für Messungs-
            und Analysedienste verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“ über die Wirkung
            unserer Werbekampagnen erstellen. Weiters bekommen wir durch Analysen einen besseren Einblick, wie Sie
            unsere Dienstleistungen, Webseite oder Produkte verwenden. Dadurch optimieren wir mit einigen dieser Tools
            Ihre Nutzererfahrung auf unserer Webseite. Beispielsweise können Sie mit den sozialen Plug-ins Inhalte auf
            unserer Seite direkt auf Facebook teilen.</p>
        <h3>Welche Daten werden von Facebook-Tools gespeichert?</h3>
        <p>Durch die Nutzung einzelner Facebook-Tools können personenbezogene Daten (Kundendaten) an Facebook gesendet
            werden. Abhängig von den benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer und IP-Adresse
            versandt werden.</p>
        <p>Facebook verwendet diese Informationen, um die Daten mit den ihren eigenen Daten, die es von Ihnen hat
            (sofern Sie Facebook-Mitglied sind) abzugleichen. Bevor Kundendaten an Facebook übermittelt werden, erfolgt
            ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig großer Datensatz in eine Zeichenkette
            transformiert wird. Dies dient auch der Verschlüsselung von Daten.</p>
        <p>Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter „Event-Daten“ sind jene Informationen
            gemeint, die wir über Sie auf unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie besuchen oder
            welche Produkte Sie bei uns kaufen. Facebook teilt die erhaltenen Informationen nicht mit Drittanbietern
            (wie beispielsweise Werbetreibende), außer das Unternehmen hat eine explizite Genehmigung oder ist rechtlich
            dazu verpflichtet. „Event-Daten“ können auch mit Kontaktdaten verbunden werden. Dadurch kann Facebook
            bessere personalisierte Werbung anbieten. Nach dem bereits erwähnten Abgleichungsprozess löscht Facebook die
            Kontaktdaten wieder.</p>
        <p>Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook die Event-Daten nur, wenn diese mit
            anderen Daten (die auf andere Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese Event-Daten
            nützt Facebook auch für Sicherheits-, Schutz-, Entwicklungs- und Forschungszwecken. Viele dieser Daten
            werden über Cookies zu Facebook übertragen. Cookies sind kleine Text-Dateien, die zum Speichern von Daten
            bzw. Informationen in Browsern verwendet werden. Je nach verwendeten Tools und abhängig, ob Sie
            Facebook-Mitglied sind, werden unterschiedlich viele Cookies in Ihrem Browser angelegt. In den
            Beschreibungen der einzelnen Facebook Tools gehen wir näher auf einzelne Facebook-Cookies ein. Allgemeine
            Informationen über die Verwendung von Facebook-Cookies erfahren Sie auch auf <a
                href="https://www.facebook.com/policies/cookies?tid=311157687" target="_blank"
                rel="noopener">https://www.facebook.com/policies/cookies</a>.</p>
        <h3>Wie lange und wo werden die Daten gespeichert?</h3>
        <p>Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die eigenen Dienste und Facebook-Produkte
            benötigt werden. Facebook hat auf der ganzen Welt Server verteilt, wo Ihre Daten gespeichert werden.
            Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten abgeglichen wurden, innerhalb von 48
            Stunden gelöscht.</p>
        <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Berichtigung, Übertragbarkeit
            und Löschung Ihrer Daten.</p>
        <p>Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr Facebook-Konto vollständig löschen. Und so
            funktioniert das Löschen Ihres Facebook-Kontos:</p>
        <p>1) Klicken Sie rechts bei Facebook auf Einstellungen.</p>
        <p>2) Anschließend klicken Sie in der linken Spalte auf „Deine Facebook-Informationen“.</p>
        <p>3) Nun klicken Sie &#8220;Deaktivierung und Löschung&#8221;.</p>
        <p>4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter und Konto löschen“</p>
        <p>5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann auf „Konto löschen“</p>
        <p>Die Speicherung der Daten, die Facebook über unsere Seite erhält, erfolgt unter anderem über Cookies (z.B.
            bei sozialen Plugins). In Ihrem Browser können Sie einzelne oder alle Cookies deaktivieren, löschen oder
            verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise.
            Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:</p>
        <p>
            <a href="https://support.google.com/chrome/answer/95647?tid=311157687" target="_blank"
                rel="noopener">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
        </p>
        <p>
            <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311157687" target="_blank"
                rel="noopener">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
        </p>
        <p>
            <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311157687"
                target="_blank" rel="noopener">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
                Computer abgelegt haben</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311157687"
                target="_blank" rel="noopener">Internet Explorer: Löschen und Verwalten von Cookies</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311157687"
                target="_blank" rel="noopener">Microsoft Edge: Löschen und Verwalten von Cookies</a>
        </p>
        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
            informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob
            Sie es erlauben oder nicht.</p>
        <p>Facebook ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
            Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC" target="_blank"
                rel="follow noopener">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC</a>. Wir hoffen
            wir haben Ihnen die wichtigsten Informationen über die Nutzung und Datenverarbeitung durch die
            Facebook-Tools nähergebracht. Wenn Sie mehr darüber erfahren wollen, wie Facebook Ihre Daten verwendet,
            empfehlen wir Ihnen die Datenrichtlinien auf <a href="https://www.facebook.com/about/privacy/update"
                target="_blank" rel="noopener">https://www.facebook.com/about/privacy/update</a>.</p>
        <h2>Instagram Datenschutzerklärung</h2>
        <p>Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram ist eine Social Media Plattform
            des Unternehmens Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012 ein
            Tochterunternehmen von Facebook Inc. und gehört zu den Facebook-Produkten. Das Einbetten von
            Instagram-Inhalten auf unserer Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie Buttons,
            Fotos oder Videos von Instagram direkt auf unserer Webseite zeigen. Wenn Sie Webseiten unserer Webpräsenz
            aufrufen, die eine Instagram-Funktion integriert haben, werden Daten an Instagram übermittelt, gespeichert
            und verarbeitet. Instagram verwendet dieselben Systeme und Technologien wie Facebook. Ihre Daten werden
            somit über alle Facebook-Firmen hinweg verarbeitet.</p>
        <p>Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum Instagram Daten sammelt, um welche Daten
            es sich handelt und wie Sie die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu Facebook
            Inc. gehört, beziehen wir unsere Informationen einerseits von den Instagram-Richtlinien, andererseits
            allerdings auch von den Facebook-Datenrichtlinien selbst.</p>
        <h3>Was ist Instagram?</h3>
        <p>Instagram ist eines der bekanntesten Social Media Netzwerken weltweit. Instagram kombiniert die Vorteile
            eines Blogs mit den Vorteilen von audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf „Insta“
            (wie viele der User die Plattform salopp nennen) Fotos und kurze Videos hochladen, mit verschiedenen Filtern
            bearbeiten und auch in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht aktiv sein wollen,
            können Sie auch nur anderen interessante Users folgen.</p>
        <h3>Warum verwenden wir Instagram auf unserer Webseite?</h3>
        <p>Instagram ist jene Social Media Plattform, die in den letzten Jahren so richtig durch die Decke ging. Und
            natürlich haben auch wir auf diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite so wohl
            wie möglich fühlen. Darum ist für uns eine abwechslungsreiche Aufbereitung unserer Inhalte
            selbstverständlich. Durch die eingebetteten Instagram-Funktionen können wir unseren Content mit hilfreichen,
            lustigen oder spannenden Inhalten aus der Instagram-Welt bereichern. Da Instagram eine Tochtergesellschaft
            von Facebook ist, können uns die erhobenen Daten auch für personalisierte Werbung auf Facebook dienlich
            sein. So bekommen unsere Werbeanzeigen nur Menschen, die sich wirklich für unsere Produkte oder
            Dienstleistungen interessieren.</p>
        <p>Instagram nützt die gesammelten Daten auch zu Messungs- und Analysezwecken. Wir bekommen zusammengefasste
            Statistiken und so mehr Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen, dass diese
            Berichte Sie nicht persönlich identifizieren.</p>
        <h3>Welche Daten werden von Instagram gespeichert?</h3>
        <p>Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie Instagrambilder oder Plug-ins)
            eingebaut haben, setzt sich Ihr Browser automatisch mit den Servern von Instagram in Verbindung. Dabei
            werden Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar unabhängig, ob Sie ein
            Instagram-Konto haben oder nicht. Dazu zählen Informationen über unserer Webseite, über Ihren Computer, über
            getätigte Käufe, über Werbeanzeigen, die Sie sehen und wie Sie unser Angebot nutzen. Weiters werden auch
            Datum und Uhrzeit Ihrer Interaktion mit Instagram gespeichert. Wenn Sie ein Instagram-Konto haben bzw.
            eingeloggt sind, speichert Instagram deutlich mehr Daten über Sie.</p>
        <p>Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon aus, dass dies bei Instagram
            genau so der Fall ist. Kundendaten sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Wichtig zu
            erwähnen ist, dass diese Kundendaten erst an Instagram übermittelt werden, wenn Sie zuvor „gehasht“ wurden.
            Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten
            verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“ übermittelt. Unter „Event-Daten“ versteht
            Facebook – und folglich auch Instagram – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass
            Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen Kontaktdaten werden mit den Daten, die
            Instagram bereits von Ihnen hat abgeglichen.</p>
        <p>Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt werden, werden die gesammelten Daten
            an Facebook übermittelt. Je nach verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto
            haben, werden unterschiedlich viele Daten gespeichert.</p>
        <p>Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook. Das
            bedeutet: wenn Sie ein Instagram-Konto haben oder <a href="http://www.instagram.com?tid=311157687"
                target="_blank" rel="noopener">www.instagram.com</a> besucht haben, hat Instagram zumindest ein Cookie
            gesetzt. Wenn das der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram, sobald Sie mit einer
            Instagram-Funktion in Berührung kommen. Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten
            wieder gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von Instagram
            beschäftigt haben, können wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.
        </p>
        <p>Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens gesetzt werden, wenn Sie auf eine
            Instagram-Funktion (wie z.B. Button oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus, dass
            Sie kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt sind, werden natürlich deutlich mehr
            Cookies in Ihrem Browser gesetzt.</p>
        <p>Diese Cookies wurden bei unserem Test verwendet:</p>
        <p>
            <strong>Name: </strong>csrftoken<br />
            <strong>Wert: </strong>&#8220;&#8221;<br />
            <strong>Verwendungszweck: </strong>Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen
            gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in Erfahrung
            bringen.<br />
            <strong>Ablaufdatum:</strong> nach einem Jahr</p>
        <p>
            <strong>Name: </strong>mid<br />
            <strong>Wert: </strong>&#8220;&#8221;<br />
            <strong>Verwendungszweck: </strong>Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und
            Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt eine eindeutige User-ID fest.<br />
            <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
        <p>
            <strong>Name:</strong> fbsr_311157687124024<br />
            <strong>Wert: </strong>keine Angaben<br />
            <strong>Verwendungszweck: </strong>Dieses Cookie speichert die Log-in-Anfrage für User der
            Instagram-App.<strong>
                <br />
            </strong>
            <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
        <p>
            <strong>Name:</strong> rur<br />
            <strong>Wert: </strong>ATN<br />
            <strong>Verwendungszweck: </strong>Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität auf
            Instagram gewährleistet.<br />
            <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
        <p>
            <strong>Name:</strong> urlgen<br />
            <strong>Wert: </strong>&#8220;\&#8221;194.96.75.33\&#8221;:
            1901:1iEtYv:Y833k2_UjKvXgYe311157687&#8221;<br />
            <strong>Verwendungszweck: </strong>Dieses Cookie dient den Marketingzwecken von Instagram.<br />
            <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
        <p>
            <strong>Anmerkung:</strong> Wir können hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im
            individuellen Fall gesetzt werden, hängt von den eingebetteten Funktionen und Ihrer Verwendung von Instagram
            ab.</p>
        <h3>Wie lange und wo werden die Daten gespeichert?</h3>
        <p>Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern und mit
            Personen, mit denen Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung der eigenen
            Datenrichtlinie. Ihre Daten sind, unter anderem aus Sicherheitsgründen, auf den Facebook-Servern auf der
            ganzen Welt verteilt. Die meisten dieser Server stehen in den USA.</p>
        <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Übertragbarkeit, Berichtigung und
            Löschung Ihrer Daten. In den Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre Daten
            auf Instagram völlig löschen wollen, müssen Sie Ihr Instagram-Konto dauerhaft löschen.</p>
        <p>Und so funktioniert die Löschung des Instagram-Kontos:</p>
        <p>Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie nach unten und klicken Sie auf
            „Hilfebereich“. Jetzt kommen Sie auf die Webseite des Unternehmens. Klicken Sie auf der Webseite auf
            „Verwalten des Kontos“ und dann auf „Dein Konto löschen“.</p>
        <p>Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie beispielsweise Ihre Fotos und Status-Updates.
            Informationen, die andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto und werden folglich
            nicht gelöscht.</p>
        <p>Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster Linie über Cookies. Diese Cookies können
            Sie in Ihrem Browser verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser funktioniert die
            Verwaltung immer ein bisschen anders. Hier zeigen wir Ihnen die Anleitungen der wichtigsten Browser.</p>
        <p>
            <a href="https://support.google.com/chrome/answer/95647?tid=311157687" target="_blank"
                rel="noopener">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
        </p>
        <p>
            <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311157687" target="_blank"
                rel="noopener">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
        </p>
        <p>
            <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311157687"
                target="_blank" rel="noopener">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
                Computer abgelegt haben</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311157687"
                target="_blank" rel="noopener">Internet Explorer: Löschen und Verwalten von Cookies</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311157687"
                target="_blank" rel="noopener">Microsoft Edge: Löschen und Verwalten von Cookies</a>
        </p>
        <p>Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie immer informiert werden, wenn ein Cookie
            gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder
            nicht.</p>
        <p>Instagram ist ein Tochterunternehmen von Facebook Inc. und Facebook ist aktiver Teilnehmer beim EU-U.S.
            Privacy Shield Framework. Dieses Framework stellt eine korrekte Datenübertragung zwischen den USA und der
            Europäischen Union sicher. Unter <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
                target="_blank"
                rel="noopener">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC </a> erfahren Sie mehr
            darüber. Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch Instagram
            näherzubringen. Auf <a href="https://help.instagram.com/519522125107875" target="_blank"
                rel="noopener">https://help.instagram.com/519522125107875</a>
            <br />
            können Sie sich noch näher mit den Datenrichtlinien von Instagram auseinandersetzen.</p>
        <h2>YouTube Datenschutzerklärung</h2>
        <p>Wir haben auf unserer Webseite YouTube-Videos eingebaut. So können wir Ihnen interessante Videos direkt auf
            unserer Seite präsentieren. YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von Google LLC ist.
            Betrieben wird das Videoportal durch YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf
            unserer Webseite eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet sich Ihr Browser
            automatisch mit den Servern von YouTube bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten
            übertragen. Für die gesamte Datenverarbeitung ist Google verantwortlich und es gilt somit auch der
            Datenschutz von Google.</p>
        <p>Im Folgenden wollen wir Ihnen genauer erklären, welche Daten verarbeitet werden, warum wir YouTube-Videos
            eingebunden haben und wie Sie Ihre Daten verwalten oder löschen können.</p>
        <h3>Was ist YouTube?</h3>
        <p>Auf YouTube können die User kostenlos Videos ansehen, bewerten, kommentieren und selbst hochladen. Über die
            letzten Jahre wurde YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit wir Videos auf
            unserer Webseite anzeigen können, stellt YouTube einen Codeausschnitt zur Verfügung, den wir auf unserer
            Seite eingebaut haben.</p>
        <h3>Warum verwenden wir YouTube-Videos auf unserer Webseite?</h3>
        <p>YouTube ist die Videoplattform mit den meisten Besuchern und dem besten Content. Wir sind bemüht, Ihnen die
            bestmögliche User-Erfahrung auf unserer Webseite zu bieten. Und natürlich dürfen interessante Videos dabei
            nicht fehlen. Mithilfe unserer eingebetteten Videos stellen wir Ihnen neben unseren Texten und Bildern
            weiteren hilfreichen Content zur Verfügung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch die
            eingebetteten Videos leichter gefunden. Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google –
            dank der gesammelten Daten – diese Anzeigen wirklich nur Menschen zeigen, die sich für unsere Angebote
            interessieren.</p>
        <h3>Welche Daten werden von YouTube gespeichert?</h3>
        <p>Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video eingebaut hat, setzt YouTube zumindest ein
            Cookie, das Ihre IP-Adresse und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann
            YouTube Ihre Interaktionen auf unserer Webseite meist mithilfe von Cookies Ihrem Profil zuordnen. Dazu
            zählen Daten wie Sitzungsdauer, Absprungrate, ungefährer Standort, technische Informationen wie Browsertyp,
            Bildschirmauflösung oder Ihr Internetanbieter. Weitere Daten können Kontaktdaten, etwaige Bewertungen, das
            Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten auf YouTube sein.</p>
        <p>Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto angemeldet sind, speichert Google Daten mit
            einer eindeutigen Kennung, die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt beispielsweise
            Ihre bevorzugte Spracheinstellung beibehalten. Aber viele Interaktionsdaten können nicht gespeichert werden,
            da weniger Cookies gesetzt werden.</p>
        <p>In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser gesetzt wurden. Wir zeigen einerseits
            Cookies, die ohne angemeldeten YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit
            angemeldetem Account gesetzt werden. Die Liste kann keinen Vollständigkeitsanspruch erheben, weil die
            Userdaten immer von den Interaktionen auf YouTube abhängen.</p>
        <p>
            <strong>Name:</strong> YSC<br />
            <strong>Wert:</strong> b9-CV6ojI5Y<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige ID, um Statistiken des
            gesehenen Videos zu speichern.<br />
            <strong>Ablaufdatum:</strong> nach Sitzungsende</p>
        <p>
            <strong>Name:</strong> PREF<br />
            <strong>Wert:</strong> f1=50000000<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt
            über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.<br />
            <strong>Ablaufdatum:</strong> nach 8 Monate</p>
        <p>
            <strong>Name:</strong> GPS<br />
            <strong>Wert:</strong> 1<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um den
            GPS-Standort zu tracken.<br />
            <strong>Ablaufdatum:</strong> nach 30 Minuten</p>
        <p>
            <strong>Name:</strong> VISITOR_INFO1_LIVE<br />
            <strong>Wert:</strong> 95Chz8bagyU<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten
            (mit eingebautem YouTube-Video) zu schätzen.<br />
            <strong>Ablaufdatum:</strong> nach 8 Monaten</p>
        <p>Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto angemeldet sind:</p>
        <p>
            <strong>Name:</strong> APISID<br />
            <strong>Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7311157687-<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu
            erstellen. Genützt werden die Daten für personalisierte Werbeanzeigen.<br />
            <strong>Ablaufdatum:</strong> nach 2 Jahre</p>
        <p>
            <strong>Name:</strong> CONSENT<br />
            <strong>Wert:</strong> YES+AT.de+20150628-20-0<br />
            <strong>Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung eines Users zur Nutzung
            unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu überprüfen und
            Userdaten vor unbefugten Angriffen zu schützen.<br />
            <strong>Ablaufdatum: </strong>nach 19 Jahren</p>
        <p>
            <strong>Name:</strong> HSID<br />
            <strong>Wert:</strong> AcRwpgUik9Dveht0I<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu
            erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu können.<br />
            <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
        <p>
            <strong>Name:</strong> LOGIN_INFO<br />
            <strong>Wert:</strong> AFmmF2swRQIhALl6aL…<br />
            <strong>Verwendungszweck:</strong> In diesem Cookie werden Informationen über Ihre Login-Daten
            gespeichert.<br />
            <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
        <p>
            <strong>Name:</strong> SAPISID<br />
            <strong>Wert:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie funktioniert, indem es Ihren Browser und Ihr Gerät
            eindeutig identifiziert. Es wird verwendet, um ein Profil über Ihre Interessen zu erstellen.<br />
            <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
        <p>
            <strong>Name:</strong> SID<br />
            <strong>Wert:</strong> oQfNKjAsI311157687-<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie speichert Ihre Google-Konto-ID und Ihren letzten
            Anmeldezeitpunkt in digital signierter und verschlüsselter Form.<br />
            <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
        <p>
            <strong>Name:</strong> SIDCC<br />
            <strong>Wert:</strong> AN0-TYuqub2JOcDTyL<br />
            <strong>Verwendungszweck:</strong> Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und
            welche Werbung Sie vor dem Besuch auf unserer Seite möglicherweise gesehen haben.<br />
            <strong>Ablaufdatum:</strong> nach 3 Monaten</p>
        <h3>Wie lange und wo werden die Daten gespeichert?</h3>
        <p>Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den Google-Servern gespeichert. Die
            meisten dieser Server befinden sich in Amerika. Unter <a
                href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank"
                rel="noopener">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>  sehen Sie genau wo
            sich die Google-Rechenzentren befinden. Ihre Daten sind auf den Servern verteilt. So sind die Daten
            schneller abrufbar und vor Manipulation besser geschützt.</p>
        <p>Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten können Sie jederzeit löschen, andere
            werden automatisch nach einer begrenzten Zeit gelöscht und wieder andere werden von Google über längere Zeit
            gespeichert. Einige Daten (wie Elemente aus „Meine Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem
            Google-Konto gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen. Auch wenn Sie nicht in
            einem Google-Konto angemeldet sind, können Sie einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft
            sind, löschen.</p>
        <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit der 2019 eingeführten automatische
            Löschfunktion von Standort- und Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung &#8211;
            entweder 3 oder 18 Monate gespeichert und dann gelöscht.</p>
        <p>Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren Browser so konfigurieren, dass Cookies
            von Google gelöscht bzw. deaktiviert werden. Je nach dem welchen Browser Sie verwenden, funktioniert dies
            auf unterschiedliche Art und Weise. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser
            verwalten:</p>
        <p>
            <a href="https://support.google.com/chrome/answer/95647?tid=311157687" target="_blank"
                rel="noopener">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
        </p>
        <p>
            <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311157687" target="_blank"
                rel="noopener">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
        </p>
        <p>
            <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311157687"
                target="_blank" rel="noopener">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
                Computer abgelegt haben</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311157687"
                target="_blank" rel="noopener">Internet Explorer: Löschen und Verwalten von Cookies</a>
        </p>
        <p>
            <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311157687"
                target="_blank" rel="noopener">Microsoft Edge: Löschen und Verwalten von Cookies</a>
        </p>
        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
            informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob
            Sie es erlauben oder nicht. Da YouTube ein Tochterunternehmen von Google ist, gibt es eine gemeinsame
            Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren Daten erfahren wollen, empfehlen wir Ihnen die
            Datenschutzerklärung unter <a href="https://policies.google.com/privacy?hl=de" target="_blank"
                rel="noopener">https://policies.google.com/privacy?hl=de.</a>
        </p>
        <p style="margin-top:15px;">Quelle: Erstellt mit dem <a title="Datenschutz Generator Deutschland"
                href="https://www.adsimple.de/datenschutz-generator/" target="_blank" rel="follow"
                style="text-decoration:none;">Datenschutz Generator</a> von AdSimple in Kooperation mit <a
                href="https://www.warkly.de" target="_blank" rel="follow" title="">warkly.de</a>
        </p>
    </div>
    <div class="image">
        <img src="../../../assets/logo/italoecu-kleeblatt.png"
            alt="ItaloEcu - Chiptuning für Alfa Romeo, Fiat, Lancia, Abarth, Jeep, Chrysler, Maserati, Ferrari aus Berlin"
            title="ItaloEcu - Chiptuning für Alfa Romeo, Fiat, Lancia, Abarth, Jeep, Chrysler, Maserati, Ferrari aus Berlin">
    </div>
</div>

<app-language-switcher></app-language-switcher>
<app-navigation></app-navigation>