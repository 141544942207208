import { OnInit, Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

    constructor(private transloco: TranslocoService) { }

    ngOnInit(): void {
    }

    changeLanguage(language: string): void {
        if (language) {
            this.transloco.setActiveLang(language);
        }
    }
}
