import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatListModule, MatSelectModule, MatSliderModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { IntroSliderComponent } from './components/intro-slider/intro-slider.component';
import { ItaloEcuLogoComponent } from './components/italo-ecu-logo/italo-ecu-logo.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TuningConfiguratorComponent } from './components/tuning-configurator/tuning-configurator.component';
import { ContactComponent } from './components/contact/contact.component';
import { ImpressComponent } from './components/impress/impress.component';
import { GaleryComponent } from './components/galery/galery.component';
import { PageNotFoundComponentComponent } from './components/page-not-found-component/page-not-found-component.component';
import { HomeComponent } from './components/home/home.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [
    AppComponent,
    IntroSliderComponent,
    ItaloEcuLogoComponent,
    NavigationComponent,
    AboutUsComponent,
    TuningConfiguratorComponent,
    ContactComponent,
    ImpressComponent,
    GaleryComponent,
    PageNotFoundComponentComponent,
    HomeComponent,
    PrivacyComponent,
    LanguageSwitcherComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSelectModule,
    MatListModule,
    HammerModule,
    HttpClientModule,
    TranslocoRootModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
